@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');


@layer base{
    body {
        @apply font-[Quicksand];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

html {
    background: #002436;
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: contain; */
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(255, 112, 219, 0.8),
        hsla(198, 70%, 63%, 0.773)
    )!important;
}